import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function OrderInfo() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/api/get-order/${id}`)
      .then((result) => {
        setOrder(result.data);
        setLoading(false);
      });
  }, []);
  window.scrollTo(0, 0);
  const [order, setOrder] = useState();

  return (
    <div id="OrderInfo" className="d-flex justify-content-center">
      <div className="container">
        <h1 style={{ marginLeft: "15px" }}>Informacion de Orden N°{id}</h1>
        <hr />
        {loading ? (
          <h3>Cargando...</h3>
        ) : (
          <>
            {!order && (
              <h3 style={{ color: "red", textAlign: "center" }}>
                Numero de Orden Incorrecto
              </h3>
            )}
            {order && order.status !== "approved" && (
              <div className="orderInfo">
                <h3 style={{ textAlign: "center" }}>
                  Orden Encontrada - Esperando Pago
                </h3>
                <div className="center">
                  <a
                    className="GoToPayButton"
                    target={"_blank"}
                    href={`${process.env.REACT_APP_API}/payOrder/${order.id}`}
                  >
                    Ir a pagar
                  </a>
                </div>
              </div>
            )}
            {order && order.status === "approved" && (
              <div className="orderInfo">
                <h3 style={{ textAlign: "center", color: "green" }}>
                  Orden Encontrada - Pago Confirmado
                </h3>
                <p style={{ textAlign: "center" }}>
                  Su orden fue confirmada. Sera notificado cuando haya salido!
                </p>
                <p style={{ textAlign: "center" }}>Muchas Gracias !</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default OrderInfo;
