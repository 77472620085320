import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../redux/cartSlice";
import Alert from "react-bootstrap/Alert";

function Product() {
  const reduxCart = useSelector((state) => state.cart);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    const getProductBySlug = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/products/${productSlug}`
      );
      setProduct(result.data);
      let newVariants = result.data.variants.map((variant) => {
        return variant;
      });
      setVariants(newVariants);
    };
    getProductBySlug();
  }, []);
  const { productSlug } = useParams();
  const [product, setProduct] = useState();
  const [added, setAdded] = useState(false);
  const [sizeSelected, setSizeSelected] = useState();
  const [variants, setVariants] = useState();
  const allSizes = ["U", "S", "M", "L"];
  return (
    <div id="Product">
      {product ? (
        <div className="center">
          <div className="container">
            <div className="row row-cols-md-2 row-cols-1">
              <div className="col">
                <Carousel>
                  <div>
                    <img src={product.path} />
                  </div>
                  {product.images &&
                    product.images.map((image) => (
                      <div key={image.id}>
                        <img src={image.url} />
                      </div>
                    ))}
                </Carousel>
              </div>
              <div className="col">
                <div className="p-5 d-flex flex-column justify-content-around productInfo">
                  <h2>{product.name}</h2>
                  {product.desc && <p>{product.desc}</p>}
                  <h4>${product.price}</h4>
                  <h5
                    style={
                      error ? { color: "red", textDecoration: "underline" } : {}
                    }
                  >
                    Escoge un tamaño {sizeSelected}
                  </h5>
                  {variants && (
                    <div className="d-flex justify-content-around">
                      {allSizes.map((allSize) => (
                        <div
                          key={allSize}
                          onClick={() => {
                            if (
                              variants.find(
                                (variant) => variant.size === allSize
                              ) &&
                              variants.find(
                                (variant) => variant.size === allSize
                              ).stock > 0
                            ) {
                              setError(false);
                              setSizeSelected(allSize);
                            }
                          }}
                          style={
                            sizeSelected === allSize
                              ? { backgroundColor: "black", color: "white" }
                              : {}
                          }
                          className={
                            variants.find(
                              (variant) => variant.size === allSize
                            ) &&
                            variants.find((variant) => variant.size === allSize)
                              .stock > 0
                              ? "size"
                              : "notSize"
                          }
                        >
                          <span>{allSize}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  {added && (
                    <Alert className="p-3" variant={"success"}>
                      ¡Producto agregado al carrito!
                    </Alert>
                  )}
                  {!added && (
                    <button
                      onClick={() => {
                        if (sizeSelected) {
                          setAdded(true);
                          localStorage.setItem(
                            "cart",
                            JSON.stringify([
                              ...reduxCart,
                              {
                                productId: product.id,
                                name: product.name,
                                price: product.price,
                                path: product.path,
                                cartId: Math.random(),
                                size: sizeSelected,
                              },
                            ])
                          );
                          dispatch(
                            setCart([
                              ...reduxCart,
                              {
                                productId: product.id,
                                name: product.name,
                                price: product.price,
                                path: product.path,
                                cartId: Math.random(),
                                size: sizeSelected,
                              },
                            ])
                          );
                          setTimeout(() => {
                            setAdded(false);
                          }, 3000);
                        } else {
                          setError(true);
                        }
                      }}
                      className="addToCartButton"
                    >
                      Agregar al Carrito
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
}

export default Product;
