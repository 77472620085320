import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ProductCard({ product }) {
  const navigate = useNavigate();
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      onClick={() => {
        if (product.variants.length > 0) {
          navigate(`/product/${product.slug}`);
        }
      }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      id="ProductCard"
      style={product.variants.length === 0 ? { cursor: "auto" } : {}}
      className="col-md-3 col-6 productBox center flex-column mt-4"
    >
      <img
        src={
          onHover
            ? product.images.length > 0
              ? product.variants.length > 0
                ? product.images[0].url
                : product.path
              : product.path
            : product.path
        }
      />
      {product.variants.length === 0 && <div className="blackOut"></div>}
      {product.variants.length === 0 && (
        <h1 className="noStock position-absolute">OUT OF STOCK</h1>
      )}
      <div className="info">
        <p>{product.name}</p>
        <p>U${product.price}</p>
      </div>
    </div>
  );
}

export default ProductCard;
