import axios from "axios";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard";

function ProProducts() {
  useEffect(() => {
    const getProductsByPro = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/products/pro`
      );
      setProducts(result.data);
    };
    getProductsByPro();
  }, []);

  const [products, setProducts] = useState();

  return (
    <div id="ProProducts">
      <div style={{minHeight:"100vh"}} className="center">
        <div className="container">
          <div className="row justify-content-center">
            {products &&
              products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProProducts;
