import React, { useState } from "react";
import { useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCart } from "../redux/cartSlice";


function Cart({ name, ...props }) {
  const navigate = useNavigate()
  const reduxCart = useSelector((state) => state.cart);
  useEffect(() => {
    let newTotal = 0;
    let newCounter = 0;
    reduxCart.map((item) => {
      newTotal = Number(item.price) + Number(newTotal);
      newCounter = newCounter + 1;
    });
    setCounter(newCounter);
    setTotal(newTotal);
  }, [reduxCart]);
  const [counter, setCounter] = useState();
  const [total, setTotal] = useState();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <>
        <p
          onClick={handleShow}
          style={{ cursor: "pointer" }}
          className="m-3 navitem position-relative"
        >
          <i
            style={
              counter > 0
                ? { fontSize: "20px"}
                : { fontSize: "20px" }
            }
            className="fa-solid fa-bag-shopping"
          ></i>
          {counter > 0 && <p className="shoppingBadge">{counter}</p>}
        </p>
        <Offcanvas id="Cart" show={show} onHide={handleClose} {...props}>
          <Offcanvas.Header className="m-3" closeButton></Offcanvas.Header>
          <div className="box">
            <h3 className="m-3">Carrito</h3>
            <div className="items">
              {reduxCart.length === 0 && (
                <h5 className="m-3">No tienes productos en el carrito</h5>
              )}
              {reduxCart &&
                reduxCart.map((item) => (
                  <div key={item.cartId} className="m-3 item">
                    <div className="row">
                      <div className="col-4">
                        <img className="itemImg" src={item.path} />
                      </div>
                      <div className="col-8">
                        <p className="price">
                          $ {item.price}
                          <i
                            onClick={() => {
                              const newCart = reduxCart.filter(
                                (itemX) => itemX.cartId !== item.cartId
                              );
                              localStorage.setItem(
                                "cart",
                                JSON.stringify(newCart)
                              );
                              dispatch(setCart(newCart));
                            }}
                            style={{ color: "black", cursor: "pointer" }}
                            className="fa-solid fa-trash-can"
                          ></i>
                        </p>
                        <p className="info mt-2">{item.name}</p>
                        <p className="info mt-2">{item.size}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {reduxCart.length > 0 && (
              <>
                <div className="total m-3">
                  <p>Total</p>
                  <p>{total}</p>
                </div>
                <div className="pay m-3">
                  <button onClick={() => {
                    navigate("/create-order")
                    handleClose()
                  }}>TERMINAR COMPRA</button>
                </div>
              </>
            )}
          </div>
        </Offcanvas>
      </>
    </div>
  );
}

export default Cart;
