import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Cart from "./Cart";
import LOGO from "../images/logo.png";

function Nav() {
  const navigate = useNavigate();
  const [tags, setTags] = useState();
  useEffect(() => {
    const getTags = async () => {
      const result = await axios.get(`${process.env.REACT_APP_API}/api/tags`);
      setTags(result.data);
    };
    getTags();
  }, []);

  return (
    <div className="center" id="Nav">
      <div className="container">
        <div className="row p-3">
          <div className="col-4 d-flex justify-content-start align-items-center">
            <p
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="dropdown-toggle d-flex align-items-center navitem"
              style={{ cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </p>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {tags &&
                tags.map((tag) => (
                  <li key={tag.id}>
                    <Link className="dropdown-item p-3" to={`/tag/${tag.slug}`}>
                      {tag.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <img
              src={LOGO}
              className="navitem logo"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">
            {/* <p style={{ cursor: "pointer" }} className="m-3 navitem">
              <i
                style={{ fontSize: "20px" }}
                className="fa-solid fa-circle-info"
              ></i>
            </p>
            <p style={{ cursor: "pointer" }} className="m-3 navitem">
              <i style={{ fontSize: "20px" }} className="fa-solid fa-phone"></i>
            </p> */}
            <Cart placement={"end"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
