import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div onClick={handleShow}>{name}</div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="OffcanvasTitle">{name}</Offcanvas.Title>
        </Offcanvas.Header>
        {name === "CONCEPT" && (
          <Offcanvas.Body className="OffcanvasBody">
            <p>
              Hola, soy Magui. Magalí en la cédula, Magui en la vida. Tengo 22
              años y un alma emprendedora desde que soy muy chiquita.
            </p>
            <p>
              La verdad es que no sé muy bien como terminé acá, solo se que me
              encanta lo que hago y que no hay nada que me apasione en el mundo
              que crear y diseñar piezas para hacerte sentir tu mejor versión.
            </p>
            <p>
              Todo empezó cuando decidí hacer ropa para mí. Mi abuela Yaya me
              ayudaba y creábamos cosas espectaculares. Mis amigas no se
              quedaron atrás, y me empezaron a pedir que les confeccione a ellas
              también. Así poco a poco, fui ganando clientas, y llegó un momento
              donde tuve que ponerle un nombre a lo que hago.
            </p>
            <p>
              Concept. “Be your own concept” pensé. Y es que ese fue el motivo
              principal por el cual decidí hacer mi propia ropa. Creaba básicos
              que me permitiera crear mi propia versión de quien quería ser, o
              como quería mostrarme y expresarme.
            </p>
            <p>
              Creé una marca que combina básicos con piezas en tendencia,
              convirtiéndolos en atemporales, para que seas “you own concept”
              durante el tiempo que quieras, cuando quieras. Además, me pareció
              fundamental apostar a la industria nacional. Al día de hoy trabajo
              con 4 talleres ubicados en distintos puntos de Uruguay.
            </p>
            <p>
              Bienvenida a mi mundo, espero que te sientas identificada con lo
              que hacemos, y puedas ser “your own concept” hoy y para siempre.
            </p>
          </Offcanvas.Body>
        )}
        {name === "Cambios y Devoluciones" && (
          <Offcanvas.Body className="OffcanvasBody">
            <p>
              Admitimos cambios en la mercadería dentro de los 15 días de
              realizada la compra, únicamente presentando boleta, bolsa, y la
              prenda sin uso con su respectiva etiqueta. De presentar signos de
              uso, lavado u olor, el cambio no será efectuado.
            </p>
            <p>
              Se puede realizar un máximo de dos cambios por compra. En caso de
              realizar un cambio proveniente de un cambio anterior, se deberá
              presentar la última factura emitida.
            </p>
            <p>
              Si el cliente desea efectuar el cambio mediante envío, el costo
              del mismo corre por su cuenta. Exceptuando los cambios o
              devoluciones que sean realizados por fallas en la prenda y/o error
              por parte de concept.
            </p>
            <p>Los productos en sale no tienen cambio.</p>
          </Offcanvas.Body>
        )}
        {name === "Envíos" && (
          <Offcanvas.Body className="OffcanvasBody">
            <p>
              Los costos de envío corren por cuenta del cliente. Dentro de
              montevideo trabajamos con cadetería propia y, para el interior,
              con DAC. Todos los envíos tienen un costo de $180,
              independientemente del destino. Los envíos solo pueden ser
              entregados en un solo lugar, por eso es importante que haya
              alguien para recibirlo. No podemos garantizar un horario exacto de
              entrega, ya que dicho servicio es tercerizado. Una vez despachado,
              el pedido no podrá ser redireccionado. El tiempo de entrega
              estimado es de entre 1 y 4 días hábiles para Montevideo, y entre 2
              y 5 días hábiles para el resto del país. El tiempo de envío
              comienza a contar a partir del momento de recibido el pago. Si la
              compra es abonada fuera del horario de atención al público, se
              procesará al día siguiente. Estos tiempos estimados no aplican
              para prendas por encargue o en preventa. Concept no se hace cargo
              por demoras o inconvenientes que provengan de la empresa de
              envíos.
            </p>
            <p>
              En caso de que el producto recibido no sea el seleccionado o
              presente algún tipo de falla o inconveniente, no dudes en
              comunicarte para solucionarlo.
            </p>
          </Offcanvas.Body>
        )}
        {name === "Cupones y descuentos" && (
          <Offcanvas.Body className="OffcanvasBody">
            <p>
              Los vales otorgados tienen vigencia de una temporada, excepto que
              se especifique en el mismo lo contrario. No podrán ser usados en
              prendas de sale.
            </p>
          </Offcanvas.Body>
        )}
      </Offcanvas>
    </>
  );
}

function Footer() {
  return (
    <div id="Footer">
      <div className="footerInfo">
        <OffCanvasExample placement="top" name="CONCEPT" />
        <OffCanvasExample placement="top" name="Cambios y Devoluciones" />
        <OffCanvasExample placement="top" name="Envíos" />
        <OffCanvasExample placement="top" name="Cupones y descuentos" />
      </div>
    </div>
  );
}

export default Footer;
