import React from "react";
import Header from "./Header";
import ProProducts from "./ProProducts";
import Tags from "./Tags/Tags";

function Home() {
  return (
    <div>
      <Header />
      <div style={{ height: "100vh" }}></div>
      {/* <Tags /> */}
      <ProProducts />
    </div>
  );
}

export default Home;
