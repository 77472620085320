import { Route, Routes } from "react-router-dom";
import "./App.css";
import CreateOrder from "./components/CreateOrder";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Nav from "./components/Nav";
import OrderInfo from "./components/OrderInfo";
import Product from "./components/Product";
import ProductsByTag from "./components/ProductsByTag";

function App() {
  return (
    <div>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tag/:tagSlug" element={<ProductsByTag />} />
        <Route path="/product/:productSlug" element={<Product />} />
        <Route path="/create-order" element={<CreateOrder />} />
        <Route path="/order/:id" element={<OrderInfo />} />
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
