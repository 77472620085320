import React from "react";
import Portrait from "../../images/banner.jpeg";

function Header() {
  return (
    <div id="Header">
      <img src={Portrait} />
    </div>
  );
}

export default Header;
