import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "./ProductCard";

function ProductsByTag() {
  const { tagSlug } = useParams();
  const [products, setProducts] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getProductsByTag = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API}/api/products/tag/${tagSlug}`
      );
      setProducts(result.data);
    };
    getProductsByTag();
  }, [tagSlug]);

  return (
    <div className="m-top-nav" id="ProductsByTag">
      <h1 className="text-center">{products && products[0].tag.name}</h1>
      <div style={{ minHeight: "80vh" }} className="center">
        <div className="container">
          <div className="row justify-content-center">
            {products &&
              products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsByTag;
